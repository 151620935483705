import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/vehiculo-electrico",
          name: "vehiculo-electrico",
          component: () => import("@/view/pages/ve.vue"),
        },
        {
          path: "/vehiculo-electrico/:comunidad",
          name: "vehiculo-electrico-comunidad",
          component: () => import("@/view/pages/ve-comunidad.vue"),
        },
        {
          path: "/vehiculo-electrico/:comunidad/:provincia",
          name: "vehiculo-electrico-provincia",
          component: () => import("@/view/pages/ve-provincia.vue"),
        },
        {
          path: "/nivel-electrificacion",
          name: "nivel-electrificacion",
          component: () => import("@/view/pages/elc.vue"),
        },
        {
          path: "/nivel-electrificacion/:comunidad",
          name: "nivel-electrificacion-comunidad",
          component: () => import("@/view/pages/elc-comunidad.vue"),
        },
        {
          path: "/nivel-electrificacion/:comunidad/:provincia",
          name: "nivel-electrificacion-provincia",
          component: () => import("@/view/pages/elc-provincia.vue"),
        },
        {
          path: "/concienciacion",
          name: "concienciacion",
          component: () => import("@/view/pages/con.vue"),
        },
        {
          path: "/concienciacion/:comunidad",
          name: "concienciacion-comunidad",
          component: () => import("@/view/pages/con-comunidad.vue"),
        },
        {
          path: "/concienciacion/:comunidad/:provincia",
          name: "concienciacion-provincia",
          component: () => import("@/view/pages/con-provincia.vue"),
        },
        {
          path: "/almacenamiento",
          name: "almacenamiento",
          component: () => import("@/view/pages/alm.vue"),
        },
        {
          path: "/autoconsumo",
          name: "autoconsumo",
          component: () => import("@/view/pages/aut.vue"),
        },
        {
          path: "/autoconsumo/:comunidad",
          name: "autoconsumo-comunidad",
          component: () => import("@/view/pages/aut-comunidad.vue"),
        },
      ],
    },

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
    {
      path: "*",
      name: "catchAll",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
