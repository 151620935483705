import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";

import VueFusionCharts from "vue-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import GammelTheme from "fusioncharts/themes/fusioncharts.theme.gammel";
import Maps from "fusioncharts/fusioncharts.maps";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";

import Spain from "fusionmaps/maps/fusioncharts.spain";
import Andalusia from "fusionmaps/maps/fusioncharts.andalusia";
import Aragon from "fusionmaps/maps/fusioncharts.aragon";
import Asturias from "fusionmaps/maps/fusioncharts.asturias";
import BalearicIslands from "fusionmaps/maps/fusioncharts.balearicislands";
import BasqueCountry from "fusionmaps/maps/fusioncharts.basquecountry";
import CanaryIslands from "fusionmaps/maps/fusioncharts.canaryislands";
import Cantabria from "fusionmaps/maps/fusioncharts.cantabria";
import CastileAndLeon from "fusionmaps/maps/fusioncharts.castileandleon";
import CastillaLaMancha from "fusionmaps/maps/fusioncharts.castillalamancha";
import Catalonia from "fusionmaps/maps/fusioncharts.catalonia";
import Ceuta from "fusionmaps/maps/fusioncharts.ceuta";
import CommunityOfMadrid from "fusionmaps/maps/fusioncharts.communityofmadrid";
import Extremadura from "fusionmaps/maps/fusioncharts.extremadura";
import Galicia from "fusionmaps/maps/fusioncharts.galicia";
import LaRioja from "fusionmaps/maps/fusioncharts.larioja";
import Melilla from "fusionmaps/maps/fusioncharts.melilla";
import Navarre from "fusionmaps/maps/fusioncharts.navarre";
import RegionOfMurcia from "fusionmaps/maps/fusioncharts.regionofmurcia";
import ValencianCommunity from "fusionmaps/maps/fusioncharts.valenciancommunity";

import regionesJson from "./regions.json";
Vue.prototype.$regions = regionesJson;

import Zoomscatter from "fusioncharts/fusioncharts.zoomscatter";
import Spainprovinces from "fusionmaps/maps/fusioncharts.spainprovinces";

Vue.use(
  VueFusionCharts,
  FusionCharts,
  Charts,
  GammelTheme,
  Maps,
  Spain,
  Spainprovinces,
  Andalusia,
  Aragon,
  Asturias,
  BalearicIslands,
  BasqueCountry,
  CanaryIslands,
  Cantabria,
  CastileAndLeon,
  CastillaLaMancha,
  Catalonia,
  Ceuta,
  CommunityOfMadrid,
  Extremadura,
  Galicia,
  LaRioja,
  Melilla,
  Navarre,
  RegionOfMurcia,
  ValencianCommunity,
  PowerCharts,
  Zoomscatter
);

// API service init
ApiService.init();

// Remove this to disable mock API
MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
